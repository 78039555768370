export interface NewDto {
    id: number
    title: string
    text: string
    creationDate: Date
}

export interface DeadLineDto {
    id: number
    dueDate: Date
    creationDate: Date
}

export interface CourseDto {
    title: string
    subtitle?: string
    subSubtitle?: string
    duration?: string
    price: string
    shortDescription: string
    description: string
    image: string
}

export enum BEUrl {
    news = 'news',
    deadLine = 'deadLine',
    posts = 'posts',
    users = 'users',
    info = 'info',
}

export interface ServerResponse<T> {
    data: T[]
}
