import MainNavigation from './navigation/MainNavigation'
function App() {
    return (
        <>
            <MainNavigation />
        </>
    )
}

export default App
