// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Car1Img from '../assets/images/Cars/Fabia-min.JPG'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Car2Img from '../assets/images/Cars/Rapid černý-min.JPG'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Car3Img from '../assets/images/Cars/Rapid modrý-min.JPG'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Car4Img from '../assets/images/Cars/Yeti automat-min.JPG'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import ZaciImg from '../assets/images/Other/Zaci.jpg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Zaci2Img from '../assets/images/Other/Zaci2.jpg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import ZakImg from '../assets/images/Other/Zak.jpg'
import { Carousel } from 'flowbite-react'

const Carusel = () => {
    return (
        <div className=' h-[18rem] md:h-[30rem]'>
            <Carousel>
                <img src={Car1Img} loading='lazy' alt='Škoda Fabia - cvičné vozidlo autoškoly' className='w-full' />
                <img src={Car2Img} loading='lazy' alt='Škoda Rapid černý - cvičné vozidlo autoškoly' className='w-full' />
                <img src={Car3Img} loading='lazy' alt='Škoda Rapid modrý - cvičné vozidlo autoškoly' className='w-full' />
                <img src={Car4Img} loading='lazy' alt='Škoda Yeti s automatickou převodovkou' className='w-full' />
                <img src={ZaciImg} loading='lazy' alt='Úspěšní absolventi autoškoly Erko' className='w-full' />
                <img src={Zaci2Img} loading='lazy' alt='Studenti autoškoly Erko' className='w-full' />
                <img src={ZakImg} loading='lazy' alt='Student při praktické výuce' className='w-full object-cover' />
            </Carousel>
        </div>
    )
}

export default Carusel
