import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../assets/images/Logo.png'
import { ProtectedRouteConstants, RouteConstants } from '../navigation/navigation-types'
import { isAuthenticated } from '../storages/user'
import { Link as LinkScroll } from 'react-scroll'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

const Navbar: React.FC = () => {
    const location = useLocation()
    const hideNavbar = location.pathname === RouteConstants.login
    const isAuth = isAuthenticated()
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false)

    if (hideNavbar) {
        return null
    }

    console.log(window.location)
    return (
        <nav className='bg-[#241823] border-b-2 border-[#1b121a] w-screen fixed z-50 top-0'>
            <div className='mx-auto max-w-[100rem] px-2 sm:px-6 lg:px-8 '>
                <div className='relative flex h-16 items-center justify-between'>
                    {isAuth && window.location.hash.includes(ProtectedRouteConstants.form) ? (
                        <>
                            <Link to={RouteConstants.home}>
                                <div className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'>
                                    Zpět
                                </div>
                            </Link>
                        </>
                    ) : (
                        <>
                            <div className='absolute inset-y-0 left-0 flex items-center md:hidden'>
                                <button
                                    type='button'
                                    className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white w-20 h-10'
                                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                                >
                                    {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
                                </button>
                            </div>
                            <div className='flex flex-1 items-center justify-center md:items-stretch md:justify-start'>
                                <div className='flex flex-shrink-0 items-center'>
                                    <LinkScroll
                                        activeClass='active'
                                        to='home'
                                        spy={true}
                                        smooth={true}
                                        offset={-150}
                                        duration={500}
                                    >
                                        <img className='block h-8 w-auto ' src={Logo} alt='Your Company' loading='lazy' />
                                    </LinkScroll>
                                </div>
                                <div className='hidden sm:ml-6 md:block'>
                                    <div className='flex space-x-4'>
                                        <LinkScroll
                                            activeClass='active'
                                            to='courses'
                                            spy={true}
                                            smooth={true}
                                            offset={-150}
                                            duration={500}
                                        >
                                            <div className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'>
                                                Co nabízíme
                                            </div>
                                        </LinkScroll>
                                        <LinkScroll
                                            activeClass='active'
                                            to='whatsNew'
                                            spy={true}
                                            smooth={true}
                                            offset={-200}
                                            duration={500}
                                        >
                                            <div className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'>
                                                Co je nového
                                            </div>
                                        </LinkScroll>

                                        <LinkScroll
                                            activeClass='active'
                                            to='aboutUs'
                                            spy={true}
                                            smooth={true}
                                            offset={-100}
                                            duration={500}
                                        >
                                            <div className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'>
                                                O nás
                                            </div>
                                        </LinkScroll>
                                        <LinkScroll
                                            activeClass='active'
                                            to='team'
                                            spy={true}
                                            smooth={true}
                                            offset={-200}
                                            duration={500}
                                        >
                                            <div className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'>
                                                Náš tým
                                            </div>
                                        </LinkScroll>
                                        <LinkScroll
                                            activeClass='active'
                                            to='tutorial'
                                            spy={true}
                                            smooth={true}
                                            offset={-150}
                                            duration={500}
                                        >
                                            <div className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'>
                                                Jak se přihlásit?
                                            </div>
                                        </LinkScroll>
                                        <LinkScroll
                                            activeClass='active'
                                            to='contact'
                                            spy={true}
                                            smooth={true}
                                            offset={-100}
                                            duration={500}
                                        >
                                            <div className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'>
                                                Kontakt
                                            </div>
                                        </LinkScroll>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {isAuth && (
                        <>
                            {!window.location.hash.includes(ProtectedRouteConstants.form) && (
                                <Link to={ProtectedRouteConstants.form}>
                                    <div className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'>
                                        Formulář
                                    </div>
                                </Link>
                            )}

                            <Link to={ProtectedRouteConstants.logout}>
                                <div className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'>
                                    Odhlásit se
                                </div>
                            </Link>
                        </>
                    )}
                </div>
            </div>
            {isMobileMenuOpen && (
                <div className='block md:hidden' id='mobile-menu'>
                    <div className='space-y-1 px-2 pt-2 pb-3'>
                        <LinkScroll
                            activeClass='active'
                            to='courses'
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            <div className='text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium cursor-pointer'>
                                Co nabízíme
                            </div>
                        </LinkScroll>

                        <LinkScroll
                            activeClass='active'
                            to='whatsNew'
                            spy={true}
                            smooth={true}
                            offset={-200}
                            duration={500}
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            <div className='text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium cursor-pointer'>
                                Co je nového
                            </div>
                        </LinkScroll>

                        <LinkScroll
                            activeClass='active'
                            to='aboutUs'
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            <div className='text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium cursor-pointer'>
                                O nás
                            </div>
                        </LinkScroll>

                        <LinkScroll
                            activeClass='active'
                            to='team'
                            spy={true}
                            smooth={true}
                            offset={-200}
                            duration={500}
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            <div className='text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium cursor-pointer'>
                                Náš tým
                            </div>
                        </LinkScroll>

                        <LinkScroll
                            activeClass='active'
                            to='tutorial'
                            spy={true}
                            smooth={true}
                            offset={-150}
                            duration={500}
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            <div className='text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium cursor-pointer'>
                                Jak se přihlásit?
                            </div>
                        </LinkScroll>

                        <LinkScroll
                            activeClass='active'
                            to='contact'
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            <div className='text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium cursor-pointer'>
                                Kontakt
                            </div>
                        </LinkScroll>
                    </div>
                </div>
            )}
        </nav>
    )
}

export default Navbar

{
    /* <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                        <div className='flex space-x-4'>
                            {
                                !isAuth && (
                                    <Link to={RouteConstants.login} >
                                        <div
                                            className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'
                                        >
                                            Přihlásit se
                                        </div>
                                    </Link>
                                )
                            }

                            {
                                isAuth && (
                                    <Link to={ProtectedRouteConstants.logout} >
                                        <div
                                            className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'
                                        >
                                            Odhlásit se
                                        </div>
                                    </Link>
                                )
                            }
                        </div>
                    </div> */
}
