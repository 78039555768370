import api from '.'
import { ProtectedRouteConstants } from '../navigation/navigation-types'
import { setUser } from '../storages/user'

export const loginUser = async (email: string, password: string) => {
    const payload = {
        email,
        password,
    }
    api.post('/login', JSON.stringify(payload)).then((res) => {
        if (res.data) {
            setUser(res.data)
            window.location.href = '#' + ProtectedRouteConstants.form
        } else {
            alert('Login failed')
        }
    })
}
