import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useMediaQuery } from 'react-responsive'
import Button from './common/Button'

interface TimeLineItemProps {
    title: string
    description: React.ReactNode
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    isOdd?: boolean
    buttonLink?: string
    buttonText?: string
}

const TimeLineItem: React.FC<TimeLineItemProps> = ({
    isOdd,
    Icon: ParamIcon,
    title,
    description,
    buttonLink,
    buttonText,
}: TimeLineItemProps) => {
    const isLargeScreen = useMediaQuery({ minWidth: 1024 })

    const [ref, inView] = useInView({
        threshold: 0,
        rootMargin: '0px',
        triggerOnce: true,
    })

    const variants = {
        visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 1 } },
        hidden: { opacity: 0, scale: 1, x: isOdd ? (!isLargeScreen ? -200 : -400) : !isLargeScreen ? 200 : 400 },
    }

    return (
        <motion.div
            ref={ref}
            variants={variants}
            initial={inView ? 'visible' : 'hidden'}
            animate={inView ? 'visible' : 'hidden'}
            transition={{ type: 'just' }}
            className={`flex flex-col items-${!isOdd ? 'start' : 'end'} mx-11`}
        >
            <div
                style={{ flexDirection: isOdd ? 'row' : 'row-reverse', boxShadow: '0px 0px 30px rgba(191, 61, 201, 0.8)' }}
                className={`max-w-[500px] bg-primary rounded-2xl px-4 py-6 flex items-center `}
            >
                <div className=' hidden lg:inline'>
                    <div
                        style={{
                            boxShadow: '0px 0px 30px #1E1E1E',
                            marginRight: isOdd ? '1.5rem' : '0.5rem',
                            marginLeft: isOdd ? '0.5rem' : '1rem',
                        }}
                        className={`bg-background rounded-full p-3 mr-6 w-16 h-16 flex items-center justify-center`}
                    >
                        <ParamIcon className='h-full w-full' />
                    </div>
                </div>
                <div>
                    <h6 style={{ textAlign: 'left' }} className={`text-secondary font-bold lg:text-xl text-3xl xl:text-3xl`}>
                        {title}
                    </h6>
                    <p style={{ textAlign: 'left' }} className={`h-full pt-3 text-secondary text-xs`}>
                        {description}
                    </p>
                    {buttonLink && buttonText && (
                        <div className='flex w-full justify-center'>
                            <Button
                                title={buttonText}
                                variant='secondary'
                                onClick={() => window.open(buttonLink, '_blank')}
                                classNames='h-9 mt-3 lg:text-base'
                            />
                            {/* <Button
                                variant='secondary'
                                title={buttonText}
                                onClick={() => window.open(buttonLink, '_blank')}
                                classNames='h-9 mt-3 border-[3px] border-white bg-transparent text-white hover:text-primary md:text-[1.2vw] lg:text-base hover:bg-white'
                            /> */}
                        </div>
                    )}
                </div>
            </div>
        </motion.div>
    )
}

export default TimeLineItem
