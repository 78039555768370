import React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimeLineItem from './TimeLineItem'
import { Stack, styled } from '@mui/material'
import { ReactComponent as Call } from '../assets/icons/callIcon.svg'
import { ReactComponent as Doctor } from '../assets/icons/doctorIcon.svg'
import { ReactComponent as Register } from '../assets/icons/registerIcon.svg'
import { ReactComponent as Pay } from '../assets/icons/payIcon.svg'
import { TimelineOppositeContent } from '@mui/lab'

const TimeLine = () => {
    const CustomTimelineConnector = styled(TimelineConnector)(() => ({
        [`&.MuiTimelineConnector-root`]: {
            backgroundColor: '#D02ED0',
            boxShadow: '0px 4px 20px #D02ED0',
            width: 10,
            paddingBottom: 200,
        },
    }))

    const CustomTimelineDot = styled(TimelineDot)(() => ({
        [`&.MuiTimelineDot-root`]: {
            backgroundColor: '#D02ED0',
            boxShadow: '0px 4px 20px #D02ED0',
            width: 24,
            height: 24,
        },
    }))

    return (
        <>
            <div className='flex flex-col space-y-7 md:hidden'>
                <TimeLineItem
                    title='1. Zavolat nám'
                    description={
                        <div>
                            <p>
                                Zavolejte nám na číslo{' '}
                                <b className='font-extrabold'>
                                    <a href='tel:724 790 187'>724 790 187</a>
                                </b>{' '}
                                a domluvíme se na všem potřebném.
                                <b className='font-extrabold'>Volejte po - pá, po 14 hodině.</b>
                            </p>
                        </div>
                    }
                    isOdd
                    Icon={Call}
                />
                <TimeLineItem
                    title='2. Potvrzení od lékaře'
                    description={
                        <div>
                            <p>
                                Vyplněný a lékařem potvrzený posudek o zdravotním stavu{' '}
                                <b className='font-extrabold'>ne starší 3 měsíců</b> (viz. Lékařský posudek ke stažení níže) -
                                přinést na první hodinu výuky teorie.
                            </p>
                        </div>
                    }
                    Icon={Doctor}
                    buttonText='Stáhnout posudek'
                    buttonLink='https://www.autoskolaerko.cz/LekarskyPosudek.docx'
                />
                <TimeLineItem
                    title='3. Elektronická přihláška'
                    description={
                        <Stack spacing={1}>
                            <p>
                                Před zahájením kurzu budou přijatí žadatelé vyzváni k vyplnění elektronické přihlášky (viz.
                                tlačítko níže).
                            </p>
                            <p className='font-extrabold'>- PROSÍME NEVYPLŇOVAT BEZ PŘEDCHOZÍ TELEFONICKÉ DOMLUVY S NÁMI.</p>
                            <p>
                                Vyplněnou žádost o řidičské oprávnění poté vytiskne autoškola a na první hodině výuky teorie ji
                                žadatelé podepíší, event. doplní o podpis zákonného zástupce.
                            </p>
                        </Stack>
                    }
                    isOdd
                    Icon={Register}
                    buttonText='Elektronická přihláška'
                    buttonLink='https://erko.moje-autoskola.cz/prihlaska.php?iframe=1'
                />
                <TimeLineItem
                    title='4. Platba'
                    description={
                        <Stack spacing={1}>
                            <p>Platbu lze provést:</p>
                            <p>
                                - převodem na účet 123-3632670217/0100 po výzvě autoškolou (do zprávy pro příjemce uveďte jméno a
                                příjmení žadatele).
                            </p>
                            <p>- hotově na 1. hodině teorie.</p>
                        </Stack>
                    }
                    Icon={Pay}
                />
            </div>

            <div className='hidden md:inline'>
                <Timeline position='alternate'>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <TimeLineItem
                                title='1. Zavolat nám'
                                description={
                                    <div>
                                        <p>
                                            Zavolejte nám na číslo{' '}
                                            <b className='font-extrabold'>
                                                <a href='tel:724 790 187'>724 790 187</a>
                                            </b>{' '}
                                            a domluvíme se na všem potřebném.{' '}
                                            <b className='font-extrabold'>Volejte po - pá, po 14 hodině.</b>
                                        </p>
                                    </div>
                                }
                                isOdd
                                Icon={Call}
                            />
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <CustomTimelineDot />
                            <CustomTimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent />
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <TimeLineItem
                                title='2. Potvrzení od lékaře'
                                description={
                                    <div>
                                        <p>
                                            Vyplněný a lékařem potvrzený posudek o zdravotním stavu{' '}
                                            <b className='font-extrabold'>ne starší 3 měsíců</b> (viz. Lékařský posudek ke stažení
                                            níže) - přinést na první hodinu výuky teorie.
                                        </p>
                                    </div>
                                }
                                Icon={Doctor}
                                buttonText='Stáhnout posudek'
                                buttonLink='https://www.autoskolaerko.cz/LekarskyPosudek.docx'
                            />
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <CustomTimelineDot />
                            <CustomTimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent />
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <TimeLineItem
                                title='3. Elektronická přihláška'
                                description={
                                    <Stack spacing={1}>
                                        <p>
                                            Před zahájením kurzu budou přijatí žadatelé vyzváni k vyplnění elektronické přihlášky
                                            (viz. tlačítko níže).
                                        </p>
                                        <p className='font-extrabold'>
                                            - PROSÍME NEVYPLŇOVAT BEZ PŘEDCHOZÍ TELEFONICKÉ DOMLUVY S NÁMI.
                                        </p>
                                        <p>
                                            Vyplněnou žádost o řidičské oprávnění poté vytiskne autoškola a na první hodině výuky
                                            teorie ji žadatelé podepíší, event. doplní o podpis zákonného zástupce.
                                        </p>
                                    </Stack>
                                }
                                isOdd
                                Icon={Register}
                                buttonText='Elektronická přihláška'
                                buttonLink='https://erko.moje-autoskola.cz/prihlaska.php?iframe=1'
                            />
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <CustomTimelineDot />
                            <CustomTimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent />
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <TimeLineItem
                                title='4. Platba'
                                description={
                                    <Stack spacing={1}>
                                        <p>Platbu lze provést:</p>
                                        <p>
                                            - převodem na účet 123-3632670217/0100 po výzvě autoškolou (do zprávy pro příjemce
                                            uveďte jméno a příjmení žadatele),
                                        </p>
                                        <p>- hotově na 1. hodině teorie.</p>
                                    </Stack>
                                }
                                Icon={Pay}
                            />
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <CustomTimelineDot />
                        </TimelineSeparator>
                        <TimelineContent />
                    </TimelineItem>
                </Timeline>
            </div>
        </>
    )
}

export default TimeLine
