import React from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import { ProtectedRouteConstants, RouteConstants, RouteParameters } from './navigation-types'
import Erko from '../screens/erko/Erko'
import Form from '../screens/form/Form'
import Login from '../screens/login/Login'
import NotFound from '../screens/notfound/NotFound'
import ProtectedRoutes from './ProtectedRoutes'
import Navbar from '../components/Navbar'
import Logout from '../screens/login/Logout'

const MainNavigation: React.FC = () => {
    return (
        <HashRouter>
            <Navbar />
            <Routes>
                <Route path={RouteConstants.home} element={<Erko />} />
                <Route path={RouteConstants.login} element={<Login />} />

                {/* LOGIN ONLY PATHS */}
                <Route
                    path='*'
                    element={
                        <ProtectedRoutes notFound={<NotFound />}>
                            <Routes>
                                <Route path={ProtectedRouteConstants.form} element={<Form />} />
                                <Route path={ProtectedRouteConstants.logout} element={<Logout />} />
                                <Route path={ProtectedRouteConstants.form + RouteParameters.uuid} element={<Form />} />
                            </Routes>
                        </ProtectedRoutes>
                    }
                />
            </Routes>
        </HashRouter>
    )
}

export default MainNavigation
