import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { loginUser } from '../../api/login'
import Button from '../../components/common/Button'
import TextField from '../../components/common/TextField'
import { RouteConstants } from '../../navigation/navigation-types'
import { isAuthenticated } from '../../storages/user'

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const isAuth = isAuthenticated()

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)


    useEffect(() => {
        if (isAuth) {
            window.location.href = "/"
        }
        return
    }, [isAuth])

    return (
        <div className='w-screen h-screen flex justify-center items-center'>

            <div className='bg-background flex flex-col items-center p-9 space-y-8 rounded-3xl w-1/3 min-w-fit' style={{
                boxShadow: '0px 0px 30px rgba(191, 61, 201, 0.8)'
            }}>
                <p className='
                    text-secondary
                    text-[4vw]
                    font-bold
                '>
                    PŘIHLÁŠENÍ
                </p>
                <TextField placeholder="Email" value={email} onChange={handleEmailChange} onEnter={() => loginUser(email, password)} />
                <TextField placeholder="Heslo" value={password} onChange={handlePasswordChange} type="password" onEnter={() => loginUser(email, password)} />

                <div className='flex space-x-8'>
                    <Link to={RouteConstants.home}>
                        <Button title="Zpět" variant='secondary' />
                    </Link>
                    <Button onClick={() => loginUser(email, password)} title="Přihlásit" />
                </div>
            </div>
        </div >
    )
}

export default Login