import React from 'react'
import { RouteConstants } from '../../navigation/navigation-types'
import { removeUser } from '../../storages/user'

const Logout = () => {
    React.useEffect(() => {
        removeUser()
        window.location.href = RouteConstants.home
    }, [])

    return (
        <div>Probíhá odhlašování</div>
    )
}

export default Logout