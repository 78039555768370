import React from 'react'
import LogoShellma from '../assets/images/Loga/PersonalLogo.png'
import LogoGympl from '../assets/images/Loga/GymplLogo.png'
import LogoMaro from '../assets/images/Loga/maroIcon.png'
import LogoLenkaC from '../assets/images/Loga/Lenka_C_.jpg'

const MobileFooter: React.FC = () => {
    return (
        <div
            style={{ height: 'calc(100% + 18px)' }}
            className='w-full lg:w-2/3 lg:absolute right-[-100px] top-[-12px] px-10 border-primary border-t-4 pb-10'
            id='contact'
        >
            <div className={`w-full h-full pt-2`}>
                <div className='text-secondary text-3xl font-bold'>
                    Autoškola <span className='text-primary'>eRko</span>
                </div>
                <div className={`w-full h-full flex flex-col lg:flex-col lg:items-center pt-[10px]`}>
                    <div className=''>
                        <div className='text-secondary text-xl font-bold mt-2'>Kontakt</div>
                        <div className='text-secondaryDark font-bold '>
                            <span className='text-secondary'>Jméno:</span>
                            Richard Melicher
                        </div>
                        <div className='text-secondaryDark font-bold '>
                            <span className='text-secondary'>Učebna teorie:</span> Gymnázium Jirkov, Mostecká 35, Jirkov
                        </div>
                        <div className='text-secondaryDark  font-bold '>
                            <span className='text-secondary'>Sídlo firmy:</span> Jasmínová 5893, 430 04 Chomutov
                        </div>
                        <div className='text-secondaryDark  font-bold '>
                            <span className='text-secondary'>Telefon pro nové žadatele:</span>{' '}
                            <a href='tel:724 790 187'>724 790 187</a> (Gabriela Melicherová)
                        </div>
                        <div className='text-secondaryDark  font-bold'>
                            <span className='text-secondary'>Email:</span>{' '}
                            <a href='mailto:autoskolaerko@seznam.cz'> autoskolaerko@seznam.cz</a>
                        </div>
                    </div>
                    <div className=''>
                        <div className='text-secondary text-xl font-bold mt-6'>Spolupracujeme</div>
                        <div className='flex w-full justify-between flex-wrap space-x-2 space-y-3'>
                            <img
                                src={LogoShellma}
                                alt='LogoShellma'
                                loading='lazy'
                                className='object-contain cursor-pointer w-1/3 md:w-1/5'
                                onClick={() => window.open('https://www.shellma.net/', '_blank')}
                            />
                            <img
                                src={LogoGympl}
                                alt='Městské gymnázium a Základní škola Jirkov'
                                loading='lazy'
                                className='object-contain cursor-pointer w-1/3 md:w-1/5'
                                onClick={() => window.open('http://web.gympljirkov.cz/', '_blank')}
                            />
                            <img
                                src={LogoMaro}
                                alt='MARO Kralovice'
                                loading='lazy'
                                className='object-contain cursor-pointer w-1/3 md:w-1/5'
                                onClick={() => window.open('https://www.maro-kralovice.cz/', '_blank')}
                            />
                            {/* <img src={LogoLenkaC} alt="PhDr. Lenka Čermáková" loading='lazy' className='object-cover cursor-pointer w-1/3 md:w-1/5' onClick={() => window.open('https://www.lenkacermakova.eu/', '_blank')} /> */}
                            <div
                                className='w-1/3 md:w-1/5 bg-secondary p-2 rounded-xl shadow-md shadow-primary cursor-pointer'
                                onClick={() => window.open('https://www.lenkacermakova.eu/', '_blank')}
                            >
                                PhDr. Lenka Čermáková <p className='text-sm text-gray-500'>akreditovaný dopravní psycholog</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileFooter
