export enum RouteConstants {
    home = '/',
    login = '/login',
}

export enum ProtectedRouteConstants {
    form = '/form',
    logout = '/logout',
}

export enum RouteParameters {
    uuid = '/:uuid',
}
