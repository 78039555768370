import React from 'react'

const LoadingNewsCard = () => {
    return (
        <div role="status" className="p-3 w-80 h-56 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700 m-4">
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div className={`h-2 bg-gray-200 w-2/${Math.ceil(Math.random() * 3) + 2} rounded-full dark:bg-gray-700 mb-2`}></div>
            <div className={`h-2 bg-gray-200 w-2/${Math.ceil(Math.random() * 3) + 2} rounded-full dark:bg-gray-700 mb-2`}></div>
            <div className={`h-2 bg-gray-200 w-2/${Math.ceil(Math.random() * 3) + 2} rounded-full dark:bg-gray-700 mb-2`}></div>
            <div className={`h-2 bg-gray-200 w-2/${Math.ceil(Math.random() * 3) + 2} rounded-full dark:bg-gray-700 mb-2`}></div>
            <div className={`h-2 bg-gray-200 w-2/${Math.ceil(Math.random() * 3) + 2} rounded-full dark:bg-gray-700 mb-2`}></div>
            <div className={`h-2 bg-gray-200 w-2/${Math.ceil(Math.random() * 3) + 2} rounded-full dark:bg-gray-700 mb-2`}></div>
            <div className={`h-2 bg-gray-200 w-2/${Math.ceil(Math.random() * 3) + 2} rounded-full dark:bg-gray-700 mb-2`}></div>
            <div className={`h-2 bg-gray-200 w-2/${Math.ceil(Math.random() * 3) + 2} rounded-full dark:bg-gray-700 mb-2`}></div>
            <div className={`h-2 bg-gray-200 w-2/${Math.ceil(Math.random() * 3) + 2} rounded-full dark:bg-gray-700 mb-2`}></div>
            <div className="h-2 bg-gray-200 w-2/3 rounded-full dark:bg-gray-700"></div>
        </div>
    )
}

export default LoadingNewsCard