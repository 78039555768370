import React from 'react'

interface ButtonProps {
    onClick?: () => void
    classNames?: string
    title?: string
    variant?: 'primary' | 'secondary'
}

const Button: React.FC<ButtonProps> = ({ onClick, title, variant = 'primary', classNames }: ButtonProps) => {
    const getVariant = () => {
        switch (variant) {
            case 'primary':
                return 'bg-primary hover:bg-primaryDark text-secondary'
            case 'secondary':
                return 'bg-secondary hover:bg-secondaryDark text-background'
        }
    }

    return (
        <div className='inline-flex rounded-md shadow cursor-pointer' onClick={onClick}>
            <div
                className={`inline-flex items-center justify-center rounded-md border border-transparent px-3 py-2 md:px-5 md:py-3 text-sm md:text-base font-medium ${getVariant()} ` + classNames}
            >
                {title}
            </div>
        </div>
    )
}

export default Button