import { Navigate, useLocation } from 'react-router-dom'
import { isAuthenticated } from '../storages/user'
import { RouteConstants, ProtectedRouteConstants } from './navigation-types'

export const ProtectedRoutes: React.FC<{ children: JSX.Element, notFound?: JSX.Element }> = ({ children, notFound }) => {
    const isAuth = isAuthenticated()
    const location = useLocation();

    const iRoutesNotFound = Object.values(ProtectedRouteConstants).some((route) =>
        location.pathname.startsWith(route)
    );

    if (notFound && !iRoutesNotFound) {
        return notFound
    }

    if (!isAuth) {
        return <Navigate to={{ pathname: RouteConstants.login }} replace={true} />
    }

    return children
}

export default ProtectedRoutes