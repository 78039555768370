import React from 'react'

interface ContactCardProps {
    name: string
    position: string
    img: string
    description: string
}

const ContactCard: React.FC<ContactCardProps> = ({ name, position, img, description }: ContactCardProps) => {
    return (
        <div className='bg-primary w-[19.9rem] h-80 flex justify-end items-center rounded-[40px] flex-col' style={{ boxShadow: '0px 0px 30px rgba(191, 61, 201, 0.8)' }}>
            <div className='bg-background w-[98%] h-3/4 rounded-b-[40px] p-4 flex flex-col items-center relative justify-center'>
                <img className="rounded-full border-2 border-primary w-[120px] h-[120px] absolute top-[-55px] object-cover" src={img} loading='lazy' />
                <h6 className='font-bold text-2xl overflow-ellipsis overflow-hidden max-h-10 text-secondary'>{name}</h6>
                <h6 className='text-xl h-10 text-center text-greyText'>{position}</h6>
                {/* <p className='overflow-ellipsis overflow-hidden text-greyText mt-2'>
                    {description}
                </p> */}
            </div>
        </div>
    )
}

export default ContactCard