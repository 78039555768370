import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Post from '../../types/post';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { getUser } from '../../storages/user';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from '../../api';

function Form() {
    const { uuid } = useParams<{ uuid: string }>()
    const User = getUser()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [formType, setFormType] = useState<string>("0")
    const handleFormTypeChange = (e: SelectChangeEvent<string>) => setFormType(e.target.value)

    // PROPERTIES FORM
    const [courseStartDate, setCourseStartDate] = useState<string>("")

    useEffect(() => {
        api.get('stats').then(res => {
            setCourseStartDate(res.data[0].course_date_start)
            setIsLoading(false)
        })
    }, [setIsLoading])


    const saveStats = () => {
        const payload = {
            course_date_start: courseStartDate
        }
        api.post(`stats`, JSON.stringify(payload)).then(res => {
            if (res.data.success) {
                setIsLoading(true)
            }
        })
    }

    // POST FORM
    const [posts, setPosts] = useState<Post[]>([])
    const [postUUID, setPostUUID] = useState<string>(uuid ?? "1")
    const [title, setTitle] = useState<string>("")
    const [description, setDescription] = useState<string>("")

    const handleTitleChange = (e: any) => setTitle(e.target.value)
    const handleDescriptionChange = (e: any) => setDescription(e.target.value)
    const handlePostUUIDChange = (e: any) => setPostUUID(e.target.value)

    useEffect(() => {
        if (posts && postUUID !== "1") {
            const post = posts.find((post: Post) => post.uuid === postUUID)
            setTitle(post?.title ?? "")
            setDescription(post?.description ?? "")
        } else if (postUUID === "1") {
            setTitle("")
            setDescription("")
        }
        return
    }, [postUUID, uuid, posts])

    useEffect(() => {
        if (isLoading) {
            api.get(`posts`).then(res => {
                setPosts(res.data)
                setIsLoading(false)
            })
        }
    }, [isLoading])

    const handleUpdatePost = () => {
        const payload = {
            uuid: postUUID, title, message: description
        }
        api.post(`posts`, JSON.stringify(payload)).then(res => {
            if (res.data.success) {
                setIsLoading(true)
            }
        })
    }

    const handleDeletePost = () => {
        api.delete(`posts&uuid=${postUUID}`).then(res => {
            setPostUUID("1")
            setIsLoading(true)
        })

    }

    const handleCreatePost = () => {
        const payload = {
            author: User?.uuid, title, message: description
        }
        api.put(`posts`, JSON.stringify(payload)).then(res => {
            setIsLoading(true)
        })
    }

    return (
        <Box sx={{
            width: "90vh",
            height: "fit-content",
            py: 3,
            px: 2,
            bgcolor: "#f7f7f7",
            mx: "auto",
            borderRadius: 6,

        }} className='mt-24'>
            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <InputLabel id="type-select-label">Typ</InputLabel>
                        <Select
                            labelId="type-select-label"
                            id="type-select"
                            value={formType}
                            label="Článek"
                            onChange={handleFormTypeChange}
                            fullWidth
                        >
                            <MenuItem value={"1"}>Články</MenuItem>
                            <MenuItem value={"2"}>Hodnoty</MenuItem>
                        </Select>
                    </Grid>
                    {formType === "1" && (
                        <>
                            <Grid item xs={12} md={4}>
                                <InputLabel id="post-select-label">Článek</InputLabel>
                                <Select
                                    labelId="post-select-label"
                                    id="post-select"
                                    value={postUUID}
                                    label="Článek"
                                    onChange={handlePostUUIDChange}
                                    fullWidth
                                >
                                    <MenuItem value={"1"}>Nový článek</MenuItem>
                                    {posts && posts?.map((post: Post) => (
                                        <MenuItem value={post.uuid} key={post.uuid}>{post.title}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={12} md={12} >
                                <InputLabel id="post-input-title">&#8205;</InputLabel>
                                <TextField value={title} onChange={handleTitleChange} label="Název" fullWidth />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField value={description} onChange={handleDescriptionChange} label="Zpráva" fullWidth multiline rows={4} />
                            </Grid>
                            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} alignItems={"center"} gap={3}>
                                {postUUID !== "1" ?
                                    <>
                                        <Button color={"primary"} variant={"contained"} onClick={() => handleUpdatePost()}>Ulozit</Button>
                                        <Button color={'error'} variant={"contained"} onClick={() => handleDeletePost()}>Smazat</Button>
                                    </>
                                    :
                                    <>
                                        <Button color={"success"} variant={"contained"} onClick={() => handleCreatePost()}>Vytvorit</Button>
                                    </>}
                            </Grid>
                        </>)}
                    {formType === "2" && (
                        <>
                            <Grid item xs={12}>
                                <Grid item xs={4}>
                                    <TextField value={courseStartDate} onChange={(e) => setCourseStartDate(e.target.value)} label="Začátek kurzu" fullWidth helperText="rok-mesic-den" />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} alignItems={"center"} gap={3}>
                                <Button color={"success"} onClick={() => saveStats()}>Ulozit</Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </Box>
    )
}

export default Form