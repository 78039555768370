import React from 'react'
import { useMediaQuery } from 'react-responsive'

const Map = () => {
    const isLargeScreen = useMediaQuery({ minWidth: 1024 })

    return (
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1222.9864683526969!2d13.45370063683262!3d50.500060695326916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470a1c68909df3cb%3A0xacde67d26dac3540!2sGymn%C3%A1zium%20Jirkov!5e1!3m2!1scs!2scz!4v1679822361497!5m2!1scs!2sc" width={isLargeScreen ? "75%" : "100%"} height="600" loading="lazy"></iframe>
    )
}

export default Map