import React from 'react'

interface TextFieldProps {
    placeholder?: string
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    type?: 'text' | 'password'
    onEnter?: () => void
}

const TextField: React.FC<TextFieldProps> = ({ value, onChange, onEnter, placeholder, type = 'text' }) => {
    return (
        <input type={type} onChange={onChange} value={value} onKeyDown={
            (e) => {
                if (e.key === 'Enter') {
                    onEnter && onEnter()
                }
            }
        } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={placeholder} />
    )
}

export default TextField