import { localStorageUserKey, secretUUID } from '../global'
import User from '../types/user'

const getUser = (): User | undefined => {
    const user = localStorage.getItem(localStorageUserKey)
    return JSON.parse(user ? user : '{}') ?? undefined
}

const setUser = (data: User) => {
    localStorage.setItem(localStorageUserKey, JSON.stringify(data))
    return
}

const removeUser = () => {
    localStorage.removeItem(localStorageUserKey)
    return
}

const isAuthenticated = () => {
    const user = getUser()
    return user && user.uuid === secretUUID ? true : false
}

export { getUser, setUser, removeUser, isAuthenticated }
