import React from 'react'
import Car2Image from '../../assets/images/Car2.png'
import NewsCard from '../../components/NewsCard'
import LoadingNewsCard from '../../components/LoadingNewsCard'
import CourseCard from '../../components/CourseCard'
import Carusel from '../../components/Carusel'
import TimeLine from '../../components/TimeLine'
import ContactCard from '../../components/ContactCard'
import { courses } from '../../data/courses'
import Post from '../../types/post'
import { useFetch } from '../../hooks/useFetch'
import { BEUrl } from '../../types'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Map from '../../components/Map'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Risa from '../../assets/images/Instruktori/Ríša-min.JPG'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Gabina from '../../assets/images/Instruktori/Gabča-min.JPG'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Zdenek from '../../assets/images/Instruktori/Zdeněk-min.JPG'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Misa from '../../assets/images/Instruktori/Míša2-min.jpg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Hromadna from '../../assets/images/Instruktori/Hromadná-min.JPG'
import LogoShellma from '../../assets/images/Loga/PersonalLogo.png'
import LogoGympl from '../../assets/images/Loga/GymplLogo.png'
import LogoMaro from '../../assets/images/Loga/maroIcon.png'
import LogoLenkaC from '../../assets/images/Loga/Lenka_C_.jpg'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-scroll'
import Button from '../../components/common/Button'
import { Grid } from '@mui/material'
import MobileFooter from '../../components/MobileFooter'
import Footer from '../../components/Footer'

const Erko: React.FC = () => {
    const { data, isLoading, error } = useFetch<Post>(BEUrl.posts)
    const isLargeScreen = useMediaQuery({ minWidth: 1024 })

    return (
        <main className='overflow-hidden'>
            <header className='w-screen container mx-auto bg-background mt-16' id='home'>
                <section className='flex flex-col md:flex-row items-center justify-between h-full px-4'>
                    <div className='z-10 py-7 md:pt-0'>
                        <h1 className='text-secondary text-l md:text-2xl font-bold'>Richard Melicher</h1>
                        <h2 className='text-secondary text-4xl md:text-8xl font-bold mt-2'>
                            Autoškola <span className='text-primary'>eRko</span>
                        </h2>
                        <p className='text-secondary text-2xl md:text-4xl font-bold mt-4 mb-2'>I autoškolu můžete mít rádi...</p>
                        <div className='flex flex-row space-x-3'>
                            <Link activeClass='active' to='courses' spy={true} smooth={true} offset={-200} duration={500}>
                                <Button title='Vybrat kurz' />
                            </Link>
                            <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-200} duration={1000}>
                                <Button title='Kontakt' variant='secondary' />
                            </Link>
                        </div>
                    </div>
                    <div className='flex items-center justify-center w-full h-[55vh] md:w-[600px] md:h-[600px] relative'>
                        <img className='object z-10 rounded-3xl' src={Hromadna} alt='Car' />
                        <div
                            style={{
                                background: 'radial-gradient(circle, rgba(255,0,251,1) 0%, rgba(255,0,251,0) 30%)',
                                width: '1750px',
                                height: '1750px',
                                zIndex: 0,
                            }}
                            className='absolute'
                        />
                    </div>
                </section>
            </header>

            <section
                className='w-screen container mx-auto mt-36 flex flex-col items-center'
                id='courses'
                aria-labelledby='courses-heading'
            >
                <h2 id='courses-heading' className='text-secondary text-6xl font-bold text-center z-10'>
                    Co nabízíme
                </h2>
                <div className={`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-16 relative mt-12 mb-8 items-center`}>
                    <div
                        style={{
                            background:
                                'radial-gradient(50% 50% at 50% 50%, rgba(191, 61, 201, 0.3) 0%, rgba(82, 26, 86, 0.128125) 59.38%, rgba(0, 0, 0, 0) 100%) ',
                            width: '1888px',
                            height: '1896px',
                            top: '-1000px',
                            left: '-1000px',
                        }}
                        className='absolute'
                    />
                    <div
                        style={{
                            background:
                                'radial-gradient(50% 50% at 50% 50%, rgba(191, 61, 201, 0.3) 0%, rgba(82, 26, 86, 0.128125) 59.38%, rgba(0, 0, 0, 0) 100%) ',
                            width: '1888px',
                            height: '1896px',
                            right: '-1000px',
                            top: '-700px',
                        }}
                        className='absolute'
                    />
                    {courses.map((course, index) => (
                        <CourseCard key={index} course={course} />
                    ))}
                </div>
                <Link activeClass='active' to='tutorial' spy={true} smooth={true} offset={-200} duration={1000} className='z-10'>
                    <Button title='Mám vybráno!' />
                </Link>
            </section>

            <section
                className='w-screen container mx-auto mt-36 relative flex flex-col items-center'
                id='whatsNew'
                aria-labelledby='news-heading'
            >
                <div
                    style={{
                        background:
                            'radial-gradient(50% 50% at 50% 50%, rgba(191, 61, 201, 0.3) 0%, rgba(82, 26, 86, 0.128125) 59.38%, rgba(0, 0, 0, 0) 100%) ',
                        width: '1888px',
                        height: '1896px',
                        top: '-500px',
                        left: '-1000px',
                        zIndex: -1,
                    }}
                    className='absolute'
                />
                {(!error || isLoading) && (
                    <>
                        <h2 id='news-heading' className='text-secondary text-6xl font-bold text-center mb-6'>
                            Co je nového
                        </h2>
                        <article className='flex flex-wrap justify-center'>
                            {!isLoading && (
                                <NewsCard
                                    data={{
                                        title: 'Tipy pro začátečníky',
                                        description:
                                            'Na odkazech níže najdete oficiální testy Ministerstva dopravy ČR a praktické rady pro začínající i zkušené řidiče.',
                                        created: new Date().toISOString(),
                                        uuid: '1',
                                        buttonLink: 'https://etesty2.mdcr.cz/',
                                        buttonText: 'E-Testy',
                                        buttonLink2: 'http://www.ibesip.cz',
                                        buttonText2: 'BESIP',
                                    }}
                                />
                            )}
                            {isLoading
                                ? [1, 2, 3, 4].map((item) => <LoadingNewsCard key={item} />)
                                : data && data.map((data: Post) => <NewsCard key={data.uuid} data={data} />)}
                        </article>
                    </>
                )}
            </section>

            <section
                className='w-screen min-h-[500px] mt-44 container mx-auto flex flex-col lg:flex-row relative px-4 space-y-6'
                id='aboutUs'
                aria-labelledby='about-heading'
            >
                <div
                    style={{
                        background:
                            'radial-gradient(50% 50% at 50% 50%, rgba(191, 61, 201, 0.3) 0%, rgba(82, 26, 86, 0.128125) 59.38%, rgba(0, 0, 0, 0) 100%) ',
                        width: '1888px',
                        height: '1896px',
                        top: '-750px',
                        right: '-1000px',
                        zIndex: -35000,
                    }}
                    className='absolute'
                />
                <div className='w-full lg:w-2/3 min-h-64'>
                    <h2 id='about-heading' className='text-secondary text-6xl font-bold mb-6'>
                        Něco málo o nás!
                    </h2>
                    <div className='flex flex-col space-y-6'>
                        <p className='text-secondary text-xl'>
                            ✓ Autoškola ERKO v chomutově nabízí kurzy pro získání řidičského oprávnění skupiny B.
                        </p>
                        <p className='text-secondary text-xl'>✓ Teoretická výuka probíhá v učebně Městského gymnázia Jirkov.</p>
                        <p className='text-secondary text-xl'>
                            ✓ Praktický výcvik probíhá na vozidlech Škoda Rapid Spaceback, Škoda Fabia III a Škoda Yeti.
                        </p>
                        <p className='text-secondary text-xl'>✓ Jezdíme převážně na území měst Chomutov a Jirkov</p>
                        <p className='text-secondary text-xl'>
                            ✓ ERKO má zkušené instruktory a nabízí flexibilní plánování výuky a výcviku.
                        </p>
                        <p className='text-secondary text-xl'>
                            ✓ ERKO nabízí i kurz pro vrácení řidičského průkazu a kondiční jízdy pro držitele řidičského
                            oprávnění.
                        </p>

                        <div className='flex flex-row space-x-5'>
                            <Link
                                activeClass='active'
                                to='courses'
                                spy={true}
                                smooth={true}
                                offset={-200}
                                duration={1000}
                                className='z-10'
                            >
                                <Button title='Chci si vybrat kurz!' />
                            </Link>
                            <Link
                                activeClass='active'
                                to='contact'
                                spy={true}
                                smooth={true}
                                offset={-200}
                                duration={1000}
                                className='z-10'
                            >
                                <Button title='Kontakt' variant='secondary' />
                            </Link>
                        </div>
                    </div>
                </div>
                <aside className='w-full lg:w-1/3 min-h-64'>
                    <Carusel />
                </aside>
            </section>

            <section
                className='w-screen container mx-auto mt-36 relative flex flex-col items-center'
                id='team'
                aria-labelledby='team-heading'
            >
                <h2 id='team-heading' className='text-secondary text-6xl font-bold text-center mb-12'>
                    Náš tým
                </h2>
                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-16 relative mb-12 items-center'>
                    <ContactCard
                        name='Richard Melicher'
                        img={Risa}
                        position='Učitel teoretické výuky a praktického výcviku'
                        description='Nevim'
                    />
                    <ContactCard
                        name='Gabriela Melicherová'
                        img={Gabina}
                        position='Učitelka teoretické výuky a praktického výcviku'
                        description='Nevim'
                    />
                    <ContactCard name='Zdeněk Buchta' img={Zdenek} position='Učitel praktického výcviku' description='Nevim' />
                    <ContactCard
                        name='Michala Slováková'
                        img={Misa}
                        position='Učitelka teoretické výuky a praktického výcviku'
                        description='Nevim'
                    />
                </div>
            </section>

            <section className='w-screen container mx-auto mt-36 flex flex-col' id='tutorial' aria-labelledby='tutorial-heading'>
                <h2 id='tutorial-heading' className='text-secondary text-6xl font-bold text-center mb-12'>
                    Jak se přihlásit?
                </h2>
                <TimeLine />
            </section>

            <section className='w-screen mx-auto max-w-[1920px] mt-36 flex flex-col-reverse lg:flex-col relative'>
                <Map />
                {isLargeScreen ? (
                    <address
                        className='w-full lg:w-2/3 lg:absolute right-[-100px] top-[-12px]'
                        style={{ height: 'calc(100% + 18px)' }}
                        id='contact'
                    >
                        <div className={`w-full h-full ${isLargeScreen ? 'trapezoid' : ''}`}>
                            <div
                                className={`w-full h-full flex flex-row lg:flex-col lg:items-center pt-[10px] ${
                                    isLargeScreen ? 'innerTrapezoid' : ''
                                }`}
                            >
                                <div className='text-secondary text-[3.3vw] 2xl:text-[60px] font-bold lg:pl-[170px] '>
                                    Autoškola <span className='text-primary'>eRko</span>
                                </div>

                                <div className='lg:pl-[160px] xl:pl-[240px]'>
                                    <div className='text-secondary text-[2vw] 2xl:text-[40px] font-bold mt-2'>Kontakt</div>
                                    <div className='text-secondaryDark text-[1vw] 2xl:text-[20px]  font-bold '>
                                        <span className='text-secondary'>Jméno:</span>
                                        Richard Melicher
                                    </div>
                                    <div className='text-secondaryDark text-[1vw] 2xl:text-[20px] font-bold '>
                                        <span className='text-secondary'>Učebna teorie:</span> Gymnázium Jirkov, Mostecká 35,
                                        Jirkov
                                    </div>
                                    <div className='text-secondaryDark text-[1vw] 2xl:text-[20px]  font-bold '>
                                        <span className='text-secondary'>Sídlo firmy:</span> Jasmínová 5893, 430 04 Chomutov
                                    </div>
                                    <div className='text-secondaryDark text-[1vw] 2xl:text-[20px]  font-bold '>
                                        <span className='text-secondary'>Telefon pro nové žadatele:</span>{' '}
                                        <a href='tel:724 790 187'>724 790 187</a> (Gabriela Melicherová)
                                    </div>
                                    <div className='text-secondaryDark text-[1vw] 2xl:text-[20px]  font-bold'>
                                        <span className='text-secondary'>Email:</span>{' '}
                                        <a href='mailto:autoskolaerko@seznam.cz'> autoskolaerko@seznam.cz</a>
                                    </div>
                                </div>
                                <div className='self-start lg: pl-[230px] xl:pl-[260px] w-[80%]'>
                                    <h2 className='text-secondary text-[2vw] 2xl:text-[40px] font-bold mt-2'>Spolupracujeme</h2>
                                    <nav className='flex w-full justify-between flex-wrap space-x-1'>
                                        <img
                                            src={LogoShellma}
                                            alt='LogoShellma'
                                            width='130'
                                            height='130'
                                            loading='lazy'
                                            className='object-contain cursor-pointer'
                                            onClick={() => window.open('https://www.shellma.net/', '_blank')}
                                        />
                                        <img
                                            src={LogoGympl}
                                            alt='Městské gymnázium a Základní škola Jirkov'
                                            width='130'
                                            height='130'
                                            loading='lazy'
                                            className='object-contain cursor-pointer'
                                            onClick={() => window.open('http://web.gympljirkov.cz/', '_blank')}
                                        />
                                        <img
                                            src={LogoMaro}
                                            alt='MARO Kralovice'
                                            width='130'
                                            height='130'
                                            loading='lazy'
                                            className='object-contain cursor-pointer'
                                            onClick={() => window.open('https://www.maro-kralovice.cz/', '_blank')}
                                        />
                                        {/* <img
                                            src={LogoLenkaC}
                                            alt='PhDr. Lenka Čermáková'
                                            width='130'
                                            height='130'
                                            loading='lazy'
                                            className='object-cover cursor-pointer'
                                            onClick={() => window.open('https://www.lenkacermakova.eu/', '_blank')}
                                        /> */}
                                        <div
                                            className='w-[150px] h-[120px] bg-secondary p-2 rounded-xl shadow-md shadow-primary cursor-pointer'
                                            onClick={() => window.open('https://www.lenkacermakova.eu/', '_blank')}
                                        >
                                            PhDr. Lenka Čermáková{' '}
                                            <p className='text-sm text-gray-500'>akreditovaný dopravní psycholog</p>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </address>
                ) : (
                    <MobileFooter />
                )}
            </section>
            <Footer />
        </main>
    )
}

export default Erko
