import axios from 'axios'
import useSWR from 'swr'
import { BEUrl } from '../types'

export function useFetch<T>(url: BEUrl) {
    const fetcher = (url: BEUrl) => axios.get(`https://shellma.net/api/${url}`).then((res) => res.data)

    const { data, error, isLoading } = useSWR<T[], Error>(url, fetcher)

    return {
        data: data,
        error,
        isLoading,
    }
}
