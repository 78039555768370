import React from 'react'
import { NewDto } from '../types'
import formatDistance from 'date-fns/formatDistance'
import { cs } from 'date-fns/locale'
import Post from '../types/post'
import Button from './common/Button'

interface NewsCardProps {
    data: Post
}

const NewsCard: React.FC<NewsCardProps> = ({ data }: NewsCardProps) => {

    return (
        <div className='bg-primary w-[20.9rem] h-72 flex items-end rounded-[40px] flex-col m-4'>
            <div className='bg-background w-[20.6rem] h-full rounded-[40px] p-4 mr-[-2px] flex flex-col space-y-3'>
                <h6 className='font-bold text-2xl overflow-ellipsis overflow-hidden max-h-10 text-secondary w-[90%] text-left rounded-t-[10px]'>{data.title}</h6>
                <div className='w-full flex space-x-2'>
                    <div className='bg-primary rounded-3xl px-3 text-secondary'>{formatDistance(new Date(data.created), new Date(), { addSuffix: true, locale: cs })}</div>
                </div>
                <p className='overflow-ellipsis overflow-hidden text-greyText'>
                    {data.description}
                </p>
                <div className='flex justify-between px-10 pt-4'>
                    {
                        data.buttonText &&
                        <Button variant='primary' title={data.buttonText} onClick={() => window.open(data.buttonLink, '_blank')} classNames='h-9 ' />
                    }
                    {
                        data.buttonText2 &&
                        <Button variant='primary' title={data.buttonText2} onClick={() => window.open(data.buttonLink2, '_blank')} classNames='h-9' />
                    }
                </div>

            </div>
        </div>
    )
}

export default NewsCard